/*------------------
Reset
--------------------*/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a {
  margin: 0;
  padding: 0;
}

/*----------------------
Helper Classes
------------------------*/
.fix {
    overflow: hidden
}
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
/*-----------------------
Typography
------------------------*/
html {
    font-size: 100%;
}
@media all and (max-width: 768px) {
    html {
        font-size: 87.5%;
    }
}
body {
    /* font-family: 'Old Standard TT', serif; */
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
p{
    font-family: 'Old Standard TT', serif;
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Poppins', sans-serif;
    color: #313131;
    font-weight: 400;
    -webkit-font-variant-ligatures: common-ligatures;
            font-variant-ligatures: common-ligatures;
    margin-top: 0;
    margin-bottom: 0;
}
h1, .h1 {
    font-size: 40px;
    font-weight: 700;
}
h2, .h2 {
    font-size: 30px;
    font-weight: 400;
}
h3, .h3 {
    font-size: 14px;
    font-weight: 400;
}
h4, .h4 {
    font-size: 14px;
    font-weight: 400;
}
h5, .h5 {
    font-size: 18px;
    font-weight: 400;
}
h6, .h6 {
    font-size: 16px;
    font-weight: 300;
}
@media all and (max-width: 767px) {
    h1, .h1 {
        font-size: 30px;
        font-weight: 400;
    }
    h2, .h2 {
        font-size: 24px;
    }
    h3, .h3 {
        font-size: 20px;
    }
    h4, .h4 {
        font-size: 16px;
    }
    h5, .h5 {
        font-size: 16px;
    }
    h6, .h6 {
        font-size: 16px;
    }
}
ul,
ol {
    list-style: none;
}

ol {
    list-style-type: upper-roman;
    list-style-position: inside;
}
strong {
    font-weight: 600;
}
hr {
    margin: 1.625em 0;
    border-color: #e6e6e6;
}
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}
a {
    color: #333;
}
a:hover,
a:focus,
a:active {
    color: #c43b68;
}
p a:hover,
p a:focus,
p a:active {
    color: #c43b68;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit
}

body {
  overflow-x: hidden;
}
/*---------------------
` 04. Transtion
-----------------------*/
a,
.btn {
    transition: all 0.3s ease-out 0s;
}
input,

select,
textarea {
    transition: all 0.5s ease-out 0s;
}

/*---------------------
Images
----------------------*/
img, .img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
}
p + img,
img:last-child {
  margin-bottom: 0;
}
body {
  background: #fff;
}
.mark, mark {
    background: #c43b68 none repeat scroll 0 0;
    color: #ffffff;
}
/*-------------------------------
Bg-Color
-------------------------------------*/
.bg__theme {
    background: #c43b68;
}
.bg__white {
  background: #fff;
}
.bg__gray {
    background: #f1f1f1;
}
.bg__cat--1{
    background-color: #111111;
}
.bg__cat--2{
    background-color: #f4f4f4;
}
.bg__cat--3{
    background-color: #f3f3f3;
}
.bg__cat--4{
    background-color: #f5f5f5;
}
.bg__cat--5{
    background-color: #010101;
}


/*-------------------------------
Social Icon 
-------------------------------*/
.color--facebook {
  color: #3b5998;
}
.color--twitter {
  color: #00aced;
}
.color--googleplus {
  color: #dd4b39;
}
.color--instagram {
  color: #125688;
}
.color--pinterest {
  color: #cb2027;
}
.color--dribbble {
  color: #ea4c89;
}
.color--behance {
  color: #053eff;
}
/*-------------------------------
Social Icon Bg Color
-------------------------------*/
.bg--facebook {
  background: #3b5998;
}
.bg--twitter {
  background: #00aced;
}
.bg--googleplus {
  background: #dd4b39;
}
.bg--instagram {
  background: #125688;
}
.bg--pinterest {
  background: #cb2027;
}
.bg--dribbble {
  background: #ea4c89;
}
.bg--behance {
  background: #053eff;
}
/*-----------------------------
Text Color
--------------------------------*/
.text--theme { 
  color: #c43b68;
 }
.text--blue { 
  color: #c43b68;
 }
.text--white { 
  color: #fff ;
}
.text--black { 
  color: #363636; 
}
.text--white { 
  color: #fff;
 }

.pos__relative{
    position: relative;
}




/*--------------------------
Bg Images
----------------------------*/

 .bg--1 {
  /* background: rgba(0, 0, 0, 0) url("./../images/bg/1.jpg") no-repeat fixed 0 0 / cover ; */
}


/*----------------------------
Background overlay 
---------------------------------*/
[data__overlay] {
    position: relative;
}
[data__overlay]:before {
    position: absolute;
    content: '';
    background: #3f51b5;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
[data__overlay] *:not(.background-image-holder):not(.instafeed--background) {
    z-index: 2;
}
[data__overlay].image--light:before {
    background: #fff;
}
[data__overlay].bg--primary:before {
    background: #425cbb;
}
[data__overlay="1"]:before {
    opacity: 0.1;
}
[data__overlay="2"]:before {
    opacity: 0.2;
}
[data__overlay="3"]:before {
    opacity: 0.3;
}
[data__overlay="4"]:before {
    opacity: 0.4;
}
[data__overlay="5"]:before {
    opacity: 0.5;
}
[data__overlay="6"]:before {
    opacity: 0.6;
}
[data__overlay="7"]:before {
    opacity: 0.7;
}
[data__overlay="8"]:before {
    opacity: 0.8;
}
[data__overlay="9"]:before {
    opacity: 0.9;
}
[data__overlay="10"]:before {
    opacity: 1;
}


/*---------------------------------
Background Black Overlay 
---------------------------------*/

[data--black__overlay] {
    position: relative;
}

[data--black__overlay]:before {
    position: absolute;
    content: '';
    background: #000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
[data--black__overlay="1"]:before {
    opacity: 0.1;
}
[data--black__overlay="2"]:before {
    opacity: 0.2;
}
[data--black__overlay="3"]:before {
    opacity: 0.3;
}
[data--black__overlay="4"]:before {
    opacity: 0.4;
}
[data--black__overlay="5"]:before {
    opacity: 0.5;
}
[data--black__overlay="6"]:before {
    opacity: 0.6;
}
[data--black__overlay="7"]:before {
    opacity: 0.7;
}
[data--black__overlay="8"]:before {
    opacity: 0.8;
}
[data--black__overlay="9"]:before {
    opacity: 0.9;
}
[data--black__overlay="10"]:before {
    opacity: 1;
}
[data--black__overlay="0"]:before {
    opacity: 0;
}

[data--black__overlay="0"] > div{
  z-index: 5;
  color: #fff;
}

/*-------------------------
Input
--------------------------*/
input[type=text],
input[type=email],
input[type=password]{
    width: 100%;
}
select {
    width: 100%;
    
}
input:focus {
    background: transparent;
    border: 1px solid #c43b68;
    outline: none;
}
textarea {
    resize: none;
    background: #c43b68;
    border: 1px solid #c43b68;
    padding: 10px;
    width: 100%;
    font-size: 14px;
}
textarea:focus {
    background: transparent;
    border: 1px solid #c43b68;
    outline: none;
}
/*----------------------------
Basic margin padding
-----------------------------*/
.m--0 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.mt--0 { margin-top: 0 }
.mt--10 { margin-top: 10px }
.mt--15 { margin-top: 15px }
.mt--20 { margin-top: 20px }
.mt--30 { margin-top: 30px }
.mt--40 { margin-top: 40px }
.mt--50 { margin-top: 50px }
.mt--60 { margin-top: 60px }
.mt--70 { margin-top: 70px }
.mt--80 { margin-top: 80px }
.mt--90 { margin-top: 90px }
.mt--100 { margin-top: 100px }
.mt--110 { margin-top: 110px }
.mt--120 { margin-top: 120px }
.mt--130 { margin-top: 130px }
.mt--140 { margin-top: 140px }
.mt--150 { margin-top: 150px }

 
.mb--0 { margin-bottom: 0 }
.mb--10 { margin-bottom: 10px }
.mb--15 { margin-bottom: 15px }
.mb--20 { margin-bottom: 20px }
.mb--30 { margin-bottom: 30px }
.mb--40 { margin-bottom: 40px }
.mb--50 { margin-bottom: 50px }
.mb--60 { margin-bottom: 60px }
.mb--70 { margin-bottom: 70px }
.mb--80 { margin-bottom: 80px }
.mb--90 { margin-bottom: 90px }
.mb--100 { margin-bottom: 100px }
.mb--110 { margin-bottom: 110px }
.mb--120 { margin-bottom: 120px }
.mb--130 { margin-bottom: 130px }
.mb--140 { margin-bottom: 140px }
.mb--150 { margin-bottom: 150px }

.p--0 {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}


.pt--0 { padding-top: 0 }
.pt--10 { padding-top: 10px }
.pt--15 { padding-top: 15px }
.pt--20 { padding-top: 20px }
.pt--30 { padding-top: 30px }
.pt--40 { padding-top: 40px }
.pt--50 { padding-top: 50px }
.pt--60 { padding-top: 60px }
.pt--70 { padding-top: 70px }
.pt--80 { padding-top: 80px }
.pt--90 { padding-top: 90px }
.pt--100 { padding-top: 100px }
.pt--110 { padding-top: 110px }
.pt--120 { padding-top: 120px }
.pt--130 { padding-top: 130px }-
.pt--140 { padding-top: 140px }
.pt--150 { padding-top: 150px }

 
.pb--0 { padding-bottom: 0 }
.pb--10 { padding-bottom: 10px }
.pb--15 { padding-bottom: 15px }
.pb--20 { padding-bottom: 20px }
.pb--30 { padding-bottom: 30px }
.pb--40 { padding-bottom: 40px }
.pb--50 { padding-bottom: 50px }
.pb--60 { padding-bottom: 60px }
.pb--70 { padding-bottom: 70px }
.pb--80 { padding-bottom: 80px }
.pb--90 { padding-bottom: 90px }
.pb--100 { padding-bottom: 100px }
.pb--110 { padding-bottom: 110px }
.pb--120 { padding-bottom: 120px }
.pb--130 { padding-bottom: 130px }
.pb--140 { padding-bottom: 140px }
.pb--150 { padding-bottom: 150px }


/*------------------------------
  19. Page section padding 
-------------------------------*/
.ptb--0 { padding: 0 }
.ptb--10 { padding: 10px 0 }
.ptb--20 { padding: 20px 0 }
.ptb--30 { padding: 30px 0 }
.ptb--40 { padding: 40px 0 }
.ptb--50 { padding: 50px 0 }
.ptb--60 { padding: 60px 0 }
.ptb--70 { padding: 70px 0 }
.ptb--80 { padding: 80px 0 }
.ptb--90 { padding: 90px 0 }
.ptb--100 { padding: 100px 0 }
.ptb--110 { padding: 110px 0 }
.ptb--120 { padding: 120px 0 }
.ptb--130 { padding: 130px 0 }
.ptb--140 { padding: 140px 0 }
.ptb--150 { padding: 150px 0 }

/*---------------------------
  20. Page section margin 
-----------------------------*/
.mtb--0 { margin: 0 }
.mtb--10 { margin: 10px 0 }
.mtb--15 { margin: 15px 0 }
.mtb--20 { margin: 20px 0 }
.mtb--30 { margin: 30px 0 }
.mtb--40 { margin: 40px 0 }
.mtb--50 { margin: 50px 0 }
.mtb--60 { margin: 60px 0 }
.mtb--70 { margin: 70px 0 }
.mtb--80 { margin: 80px 0 }
.mtb--90 { margin: 90px 0 }
.mtb--100 { margin: 100px 0 }
.mtb--110 { margin: 110px 0 }
.mtb--120 { margin: 120px 0 }
.mtb--130 { margin: 130px 0 }
.mtb--140 { margin: 140px 0 }
.mtb--150 { margin: 150px 0; }


/*-----------------------------
  21. Custom cloumn
--------------------------------*/
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10 {
    float: left;
}
.col-1{
    width: 10%;
}
.col-2{
    width: 20%;
}
.col-3{
    width: 30%;
}
.col-4{
    width: 40%;
}
.col-5{
    width: 50%;
}
.col-6{
    width: 60%;
}
.col-7{
    width: 70%;
}
.col-8{
    width: 80%;
}
.col-9{
    width: 90%;
}
.col-10{
    width: 100%;
}
/*------------------------
 Others
-------------------------*/
p.form-messege{
  margin-top:15px;font-size:14px
}
p.success {
  color: green;
}
p.error {
  color: red;
}

/*-----------------------------------------
  22. Moz Placeholder
------------------------------------------*/

*::-moz-selection {
    background: #c43b68;
    color: #fff;
    text-shadow: none;
}
::-moz-selection {
    background: #c43b68;
    color: #fff;
    text-shadow: none;
}
::selection {
    background: #c43b68;
    color: #fff;
    text-shadow: none;
}


input [type="text"]:focus, 
input [type="text"]:focus::-moz-placeholder,
input [type="email"]:focus, 
input [type="email"]:focus::-moz-placeholder{
  color: #333333;
}

/* 
input::-webkit-input-placeholder { / Chrome/Opera/Safari /
  color: #444;
  opacity: 1;
}
input::-moz-placeholder { / Firefox 19+ /
  color: #444;
  opacity: 1;
}
input:-ms-input-placeholder { / IE 10+ /
  color: #444;
  opacity: 1;
}

textarea::-webkit-input-placeholder { / Chrome/Opera/Safari /
  color: #444;
  opacity: 1;
}
textarea::-moz-placeholder { / Firefox 19+ /
  color: #444;
  opacity: 1;
}
textarea:-ms-input-placeholder { / IE 10+ /
  color: #444;
  opacity: 1;
} 
*/