body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading--spinner {
  position: fixed; 
  left: 50%; 
  top: 50%; 
  z-index: 4;
  background-color: #ffffff;
  padding: 10px;
}
.seperator {
  height: 1.2em;
  border-right: 2px solid #c3c1bf;
}
.min-height-1{
  min-height: 760px;
}

/*slider*/
.carousel-control-prev-icon, .carousel-control-next-icon{
  background-color: #c43b68;
  border-radius: 100%;
  height: 42px !important;
  width: 42px !important;
  background-size: 76% !important;
}
.bg-gray{
  background-color: #f3f3f3;
}
.text-left {
  text-align: left !important;
}
.carousel-inner {
  padding-top: 26px;
}
.slide__thumb img {
  max-width: 360px;
  padding-bottom: 21px;
}
.font-1{
  font-size: 18px !important;
}