.message-top{
  
}

.thumbnail_images ul li:hover {
  border: 2px solid #000;
}
.thumbnail_images ul li {
  margin: 5px;
  padding: 10px;
  border: 2px solid #eee;
  cursor: pointer;
  transition: all 0.5s;
  display: inline-block;
}
.thumbnail_images ul {
  list-style: none;
  justify-content: center;
  display: inline-block;
  align-items: center;
  margin-top: 10px;
}

.product-img {
  height: 242px;
  text-align: center;
  border: 1px solid #f5f4f4;
  padding: 3px;
}
.product-img img {
  height: 100%;
  width: 100%;
}

.cart-table .media-object {
  width: 80px;
}

.cart-quantity span {
  width: 35px;
  display: inline-block;
  font-weight: bold;
}

.empty-cart .img-thumbnail {
  padding: 0.25rem;
  border: none;
  width: 380px;
  height: auto;
}



/* login */


.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}


.list-group-item{
  border: 1px solid #DDDDDD !important;
}