/*-----------------------------------------------------------------------------------
  Template Name: Asbab eCommerce HTML5 Template.
  Description: Asbab is a unique website template designed in HTML with a simple & beautiful look.

-------------------------------------------------------------------------------------*/
/*
    font-family: 'Old Standard TT', serif;

    font-family: 'Poppins', sans-serif;

*/

/*----------------------------------------
  Menu basic style
------------------------------------------*/
.header{
  z-index: 10;
  position: relative;
}
.main__menu {
  display: flex;
  justify-content: center;
}
.main__menu > li > a {
    color: #333333;
    display: block;
    font-size: 14px;
    height: 80px;
    line-height: 80px;
    position: relative;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}
.main__menu li {
    position: relative;
    margin: 0 20px;
}
.main__menu > li:hover > a{
  color: #c43b68;
}
.logo a{
  display: block;
}
.header__right {
  display: flex;
  justify-content: flex-end;
  height: 80px;
  align-items: center;
}









/*---------------------------
  Header Two
-----------------------------*/

.offsetmenu.offsetmenu__on{
  opacity: 1;
  padding-bottom: 50px;
  left: calc(0px - 16px);
}
.offsetmenu{
  left: -100%;
}
.offsetmenu{
  overflow-y: inherit !important;
}













































/*---------------------------
  Header three
-----------------------------*/


.menu__click.menu__close__btn {
    right: 25px;
    position: absolute;
    top: 25px;
}
.menu__click.menu__close__btn  a i {
    background: #000 none repeat scroll 0 0;
}

/*------------------------
  Header 5
---------------------------*/



/* New Code */
.off__canvars__wrap.right--side.width-half {
    position: fixed;
    width: calc(50% + 2px);
    right: 0;
    top: 0;
    background: rgba(63, 217, 178, 0.85);
    z-index: 50;
    height: 100vh;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out 0s;
}
nav.off__canvars--menu ul li a {
    color: #fff;
    font-size: 20px;
    text-transform: capitalize;
}
nav.off__canvars--menu ul li {
    text-align: center;
    padding: 10px 0;
}
.off__canvars__open{
  overflow: hidden;
}

.off__canvars__wrap.right--side.width-half{
  opacity: 0;
  visibility: hidden; 
  z-index: -999;
  right: -90%;
}

.off__canvars__wrap.right--side.width-half.off__canvars__wrap__on{
  opacity: 1;
  visibility: visible;
  z-index: 99999999;
  right: 0;
}

nav.off__canvars--menu ul li ul li {
    z-index: 99999999;
}
nav.off__canvars--menu ul li ul li a {
    color: #fff;
    z-index: 99999999;
}

nav.off__canvars--menu ul li a:hover {
    color: #ddd;
}

nav.off__canvars--menu ul li ul li a:hover {
    color: #ddd;
}




















/*-----------------------
  Header One Search
-----------------------*/

.header__search a i {
    color: #000000;
    font-size: 19px;
    position: relative;
}

.cod__shopping__cart {
    position: relative;
}
.cod__shopping__cart a i {
    color: #000000;
    font-size: 19px;
}
.cod__shopping__cart a span.cod__qua {
  background: #c43b68;
  border-radius: 100%;
  color: #fff;
  font-size: 9px;
  height: 17px;
  line-height: 19px;
  position: absolute;
  right: -5px;
  text-align: center;
  top: -4px;
  width: 17px;
}
.header__search a {
  padding-right: 20px;
  position: relative;
  margin-right: 20px;
}
.header__search a::before {
  background: #898989;
  content: "";
  height: 15px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-70%);
          transform: translateY(-70%);
  width: 2px;
}

.header__account a {
    color: #000;
    font-size: 19px;
    margin-right: 20px;
    padding-right: 20px;
    position: relative;
}
.header__account a::before {
    background: #898989 none repeat scroll 0 0;
    content: "";
    height: 15px;
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
}



/*-----------------------------------------
  Sticky  For  Header
-------------------------------------------*/

.sticky__header.scroll-header {
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
          animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  background: rgba(246, 246, 248, 0.9) none repeat scroll 0 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  left: 0;
  margin: 0 auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}
.header--3 .scroll-header .menumenu__container {
  background: transparent;
}


.fluid-container.mobile-menu-container {
  position: relative;
}
.mobile-logo {
  align-items: center;
  display: flex;
  height: 52px;
  left: 0;
  padding-left: 15px;
  position: absolute;
  top: 0;
  z-index: 2147483647;
}
.mobile-logo img {
  max-height: 25px;
}

/*-----------------------------
  Dropdown Menu
--------------------------------*/
.main__menu li.drop{
    position: relative;
}
.main__menu li.drop ul.dropdown {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 120%;
  transition: all 0.2s ease-in-out 0s;
  visibility: hidden;
  width: 220px;
  z-index: 999;
}
.scroll-header .main__menu li.drop ul.dropdown {
  top: 120%;
}
.scroll-header .main__menu li.drop:hover ul.dropdown {
  top: 100%;
}
.main__menu li.drop:hover ul.dropdown {
    opacity: 1;
    visibility: visible;
    top: 100%;
}
.main__menu ul.dropdown li {
    border-bottom: 1px solid #f4f4f4;
    display: block;
}
.main__menu ul.dropdown li a {
    background: #ffffff none repeat scroll 0 0;
    color: #333;
    display: block;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out 0s;
    padding: 9px 18px;
    transition: 0.3s;
    font-family: 'Poppins', sans-serif;
}
.main__menu li.drop ul.dropdown li:hover > a {
    background: #fafafa none repeat scroll 0 0;
    color: #c43b68;
}
.main__menu li.drop ul.dropdown li {
    margin: 0;
}




/*-------------------------------
  Mega Menu
---------------------------------*/

.main__menu li.drop ul.dropdown.mega_dropdown {
  width: 654px;
}

.dropdown.mega_dropdown {
    width: 655px;
}
.dropdown.mega_dropdown {
    display: flex;
    justify-content: space-between;
    padding: 30px !important;
}
.dropdown.mega_dropdown li a.mega__title {
    display: inline-block;
    font-size: 20px;
    margin-bottom: 20px;
    padding: 0 0 10px;
    position: relative;
    text-transform: capitalize;
}
.dropdown.mega_dropdown li a.mega__title::before {
    background: #000 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
}

.main__menu li.drop ul.dropdown.mega_dropdown {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.main__menu li.drop ul.dropdown.mega_dropdown li:hover > a {
  background: transparent none repeat scroll 0 0;
  color: #c43b68 ;
}
.main__menu ul.dropdown.mega_dropdown li a {
  padding: 4px 0 12px;
}
.main__menu ul.dropdown.mega_dropdown li {
  border-bottom: 1px solid transparent;
}
























/*---------------------------------
  cd-words-wrapper
--------------------------------*/
.theme-color .cd-words-wrapper {
  padding: 0px !important;
}




/*----------------------
  Header Top One
------------------------*/


.select__language {
    display: flex;
    margin-right: 34px;
}
.select__language li a {
    color: #333333;
    text-transform: uppercase;
}

.select__language > li > ul > li > a {
    color: #333333;
}

.select__language > li:hover > a , .select__language > li > ul > li > a:hover , .cod__contact a:hover{
    color: #c43b68;
}
.cod__contact a {
    color: #333333;
    font-size: 15px;
}
.cod__header__top__left {
    align-items: center;
    display: flex;
    height: 130px;
}
.cod__header__right li a {
    color: #333333;
    text-transform: capitalize;
    transition: 0.4s;
}
.cod__header__right li a:hover{
    color: #c43b68;
}
.cod__header__right {
    align-items: center;
    display: flex;
    height: 130px;
    justify-content: flex-end;
}
.logo {
    align-items: center;
    display: flex;
    height: 80px;
    justify-content: flex-start;
}

.cod__contact a i {
    color: #c43b68;
    padding-right: 8px;
}
.cod__header__right li + li {
    margin-left: 30px;
}

/*-----------------------------------------
  Header Language Option
-------------------------------------------*/

.select__language > li + li {
    margin-left: 8px;
    padding-left: 18px;
}

.select__language li {
    position: relative;
}

.select__language li.drop--lan .language__option {
    background: #fff none repeat scroll 0 0;
    left: 0;
    opacity: 0;
    padding: 10px 20px;
    position: absolute;
    top: 100%;
    transition: all 0.4s ease 0s;
    visibility: hidden;
    width: 150px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    z-index: 2;
}
.select__language li.drop--lan:hover .language__option {
    opacity: 1;
    visibility: visible;
}
.language__option li {
    padding: 3px 0;
}








/*-----------------------------------------
  Header Search area
-------------------------------------------*/
.search__area .search__inner form button::before {
  color: #333;
  content: "\e090";
  display: block;
  font-family: 'simple-line-icons';
  font-size: 24px;
  transition: color 300ms ease 0s;
}
.search__area {
  background: #cfcfcd none repeat scroll 0 0;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.08);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transform: translateY(-200%);
          transform: translateY(-200%);
  transition: all 300ms ease 0s;
  z-index: 2147483647;
}
.search__area .search__inner{
  position: relative;
}
.search__area .search__inner form {
  margin: 4em 0;
  padding: 0 40px 0 0;
  position: relative;
  text-align: center;
}
.search__area .search__inner form input[type="text"] {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  color: #333;
  font-size: 25px;
  font-weight: 300;
  height: 60px;
  line-height: 60px;
  padding: 0 70px 0 20px;
  text-align: left;
}
.search__area .search__inner form input[type="text"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #333;
  font-size: 25px;
  font-weight: 300;
}
.search__area .search__inner form input[type="text"]::-moz-placeholder { /* Firefox 19+ */
  color: #333;
  font-size: 25px;
  font-weight: 300;
}
.search__area .search__inner form input[type="text"]:-ms-input-placeholder { /* IE 10+ */
  color: #333;
  font-size: 25px;
  font-weight: 300;
}
.search__area .search__inner form input[type="text"]:-moz-placeholder { /* Firefox 18- */
  color: #333;
  font-size: 25px;
  font-weight: 300;
}
.search__area .search__inner form button {
  background: transparent none repeat scroll 0 0;
  border: 0 none;
  border-radius: 0;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  position: absolute;
  right: 40px;
  top: 0;
  width: 60px;
  transition: 0.3s;
}
.search__area .search__inner form button:hover {
  background: #c43b68 none repeat scroll 0 0;
}
.search__area .search__inner form button:hover::before {
  color: #fff;
}
.search__area .search__inner .search__close__btn {
  display: block;
  line-height: 58px;
  position: absolute;
  right: 0;
  top: 0;
}
.search__area .search__inner .search__close__btn {
  color: #fff;
  cursor: pointer;
  font-size: 21px;
  line-height: 58px;
}
.search__close__btn .search__close__btn_icon i {
    transition: all 0.5s ease 0s;
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
}
.search__close__btn .search__close__btn_icon:hover i {
    -webkit-transform: scale(2) rotate(180deg);
            transform: scale(2) rotate(180deg);
    color: #c43b68;
}
.search__box__show__hide .search__area {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  transition: all 300ms ease 0s;
}

/*--------------------------
  Body Onerlay Menu
----------------------------*/
.body__overlay {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: url("../../images/close.png") 25 25, pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s, -webkit-transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s, transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s, transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    visibility: hidden;
    width: 100%;
    z-index: 99999;
}
.body__overlay.is-visible {
    opacity: 1;
    transition: opacity 0.3s ease 0s, visibility 0s ease 0s, -webkit-transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, visibility 0s ease 0s, transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, visibility 0s ease 0s, transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    visibility: visible;
}

/*--------------------------
  Toggle menu
----------------------------*/

.shopping__cart, 
.user__meta  {
    background: #eeeeee none repeat scroll 0 0;
    box-shadow: 0 0 85px rgba(0, 0, 0, 0.2);
    display: block;
    height: 100vh;
    opacity: 0;
    overflow-y: scroll;
    position: fixed;
    right: -100%;
    top: 0;
    transition: all 0.25s ease 0s;
    width: 100%;
    z-index: 99999;
    width: 475px;

}
.offsetmenu {
  background: #c43b68;
  box-shadow: 0 0 85px rgba(0, 0, 0, 0.2);
  display: block;
  height: 100vh;
  opacity: 0;
  overflow-y: scroll;
  position: fixed;
  right: -100%;
  top: 0;
  transition: all 0.25s ease 0s;
  width: 475px;
  z-index: 99999;
}
.user__meta .cod__login__register {
    padding-top: 30px;
}
.offsetmenu.offsetmenu__on,
.shopping__cart.shopping__cart__on,
.user__meta.user__meta__on {
    opacity: 1;
    padding-bottom: 50px;
    right: calc(0px - 16px);
}
.offsetmenu__inner, 
.shopping__cart__inner, 
.user__meta__inner {
    height: 100%;
    padding: 60px 50px 60px;
}
.offsetmenu__close__btn {
    background-color: #eee;
    padding: 29px 50px 24px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
}
.offsetmenu__close__btn a i {
    color: #666666;
    font-size: 36px;
    transition: all 0.3s ease 0s;
}
.offsetmenu__close__btn a:hover i{
    color: #c43b68;
}
.offset__widget {
    display: flex;
    justify-content: space-between;
    margin-bottom: 19px;
    margin-top: 7px;
}
h4.offset__title {
    color: #444444;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    text-transform: capitalize;
}
.offset__single ul li a {
    color: #303030;
    font-size: 14px;
    text-transform: capitalize;
    transition: all 0.4s ease 0s;
}
.offset__single ul li + li {
    padding: 2px 0 0;
}

.offset__single ul li a:hover {
    color: #c43b68;
}
.off__contact p {
    color: #303030;
    font-size: 16px;
    line-height: 28px;
    width: 98%;
    text-align: center;
}
.offset__sosial__share {
    margin-top: 21px;
    text-align: center;
}
.off__soaial__link {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}
.off__soaial__link li + li {
    padding-left: 15px;
}
.off__soaial__link li a{
    height: 35px;
    line-height: 35px;
    width: 35px;
    text-align: center;
    transition: all 0.3s ease 0s;
    display: block;
}
.off__soaial__link li a i {
    color: #fff;
    font-size: 16px;
}
.off__soaial__link li a:hover i {
    color: #fff;
}
.off__contact {
    margin-bottom: 25px;
}
.off__contact .logo {
    margin-bottom: 18px;
    text-align: center;
}

.sidebar__thumd {
    display: flex;
    flex-wrap: wrap;
    margin: 32px -7px 30px;
}
.sidebar__thumd li {
    margin: 0 7px 15px;
    position: relative;
}
.sidebar__thumd a img {
    width: 100%;
}
.sidebar__thumd > li a::before {
    background: #c43b68  none repeat scroll 0 0;
    content: "";
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease 0s;
    width: 100%;
}
.sidebar__thumd li a::after {
    color: #fff;
    content: "";
    font-family: themify;
    left: 40%;
    opacity: 0;
    position: absolute;
    top: 33%;
    transition: all 0.5s ease 0s;
}
.sidebar__thumd li a {
    position: relative;
}
.sidebar__thumd > li a {
    display: block;
}
.sidebar__thumd li a:hover::after {
    opacity: 1;
}
.sidebar__thumd li a:hover::before {
    opacity: 0.7;
}
.shopping__cart__inner {
    padding: 102px 50px;
}
.shopping__cart__inner .offsetmenu__close__btn {
    padding: 21px 50px 24px;
}


/*---------------------------------
  Shopping Cart Area
---------------------------------*/

.shp__pro__details h2 {
    border-top-width: 0;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    padding: 0;
    text-transform: none;
}
.shoping__total .subtotal {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
}
.shp__pro__details .shp__price {
    color: #c43b68;
    display: block;
}

.shoping__total .subtotal {
    color: #000;
    font-weight: 400;
}
.shoping__total .total__price {
    color: #c43b68;
    float: right;
}
.shopping__btn li a {
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
    font-size: 14px;
    height: 57px;
    line-height: 57px;
    margin-bottom: 15px;
    padding: 0 25px;
    text-align: center;
    text-transform: none;
    transition: all 0.5s ease 0s;
    width: 100%;
    display: block;
}
.shopping__btn li a:hover{
    background: #c43b68;
    border: 1px solid #c43b68;
    color: #fff;
}
.shopping__btn .shp__checkout a{
    background-color: #000;
    color: #fff;
}
.shp__single__product {
    display: flex;
    justify-content: space-between;
    padding-bottom: 21px;
    padding-top: 21px;
}
.shp__pro__thumb {
    margin-right: 20px;
}
.shp__pro__thumb a {
    display: block;
}
.shp__pro__thumb a img {
    max-width: 60px;
}
.remove__btn {
    text-align: right;
    width: 20px;
}
.shp__pro__details {
    min-width: 71%;
}
.shp__cart__wrap {
    margin-top: -30px;
}
.shoping__total {
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    padding: 22px 0;
}
.remove__btn a i {
    color: #ccc;
    font-size: 22px;
    transition: all 0.5s ease 0s;
}
.remove__btn a:hover i {
    color: #c43b68;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.shp__cart__wrap .shp__single__product + .shp__single__product {
  border-top: 1px solid #ddd;
}
