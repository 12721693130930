a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-bar {
  background: rgba(0, 0, 0, 0) none repeat scroll center bottom;
  float: left;
  min-height: auto;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 99;
}

.mean-container a.meanmenu-reveal {
  border: 2px solid #444;
  color: #444;
  cursor: pointer;
  display: block;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 1px;
  font-weight: 700;
  height: 22px;
  line-height: 22px;
  margin-top: -56px;
  padding: 5px 6px;
  position: absolute;
  right: 0;
  text-decoration: none;
  text-indent: -9999em;
  top: 0;
  width: 22px;
}

.mean-container a.meanmenu-reveal span {
  background: #444 none repeat scroll 0 0;
  display: block;
  height: 3px;
  margin-top: 3px;
}

.mean-container .mean-nav {
  background: #0c1923 none repeat scroll 0 0;
  float: left;
  margin-top: 0;
  width: 100%;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}

.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 1em 5%;
  margin: 0;
  text-align: left;
  color: #fff;
  border-top: 1px solid #383838;
  border-top: 1px solid rgba(255,255,255,0.2);
  text-decoration: none;
  text-transform: uppercase;
}

.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 1em 10%;
  border-top: 1px solid #f1f1f1;
  border-top: 1px solid rgba(255,255,255,0.25);
  opacity: 0.75;
  filter: alpha(opacity=75);
  text-shadow: none !important;
  visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: none;
  margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
  background: #252525;
  background: rgba(255,255,255,0.1);
}

.mean-container .mean-nav ul li a.mean-expand {
  -moz-border-bottom-colors: none !important;
  -moz-border-left-colors: none !important;
  -moz-border-right-colors: none !important;
  -moz-border-top-colors: none !important;
  background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
  border-color: -moz-use-text-color -moz-use-text-color rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.4) !important;
  font-weight: 700;
  height: 32px;
  margin-top: 1px;
  padding: 12px 12px 7px !important;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 26px;
  z-index: 2;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: rgba(0,0,0,0.9);
}

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}


.mean-container .mean-bar, .mean-container .mean-bar * {
  box-sizing: content-box;
}


.mean-remove {
  display: none !important;
}
